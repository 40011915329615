body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #6c5dd3;
  --secondary-color: #6c757d;
  --text-color: #808191;
  --input-border: #dedede;
  --input-background: #f5f5f5;
  --input-color: #11142d;
  --input-white: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.invalid_txt  {
  color: red;
  font-size: 14px;
}

.form_group input.is_invalid,
select.my-select.is_invalid,
.my-select.is_invalid .css-13cymwt-control {
  border-color: red;
  outline: none;
}

.form_group input:focus,
.form_group input:focus-visible {
  border-color: var(--primary-color);
  background: var(--input-white);
}

.form_group {
  margin-bottom: 20px;
}

.form_group input {
  width: 100%;
  height: 50px;
  padding: 0 35px 0px 15px;
  border-radius: 12px;
  border: 1px solid var(--input-border);
  background: var(--input-background);
  font-size: 12px;
  font-weight: 500;
  color: var(--input-color);
  transition: all 0.2s;
  outline: none;
  line-height: 16px;
  letter-spacing: 0.9px;
}
.myinput input {
  background: #f5f5f5;
  background: var(--input-background);
  border: 1px solid #dedede;
  border: 1px solid var(--input-border);
  border-radius: 12px;
  color: #11142d;
  color: var(--input-color);
  font-size: 12px;
  font-weight: 500;
  height: 50px;
  letter-spacing: .9px;
  line-height: 16px;
  outline: none;
  padding: 0 35px 0 15px;
  transition: all .2s;
  width: 100%;
}
.myinput input:focus,
.myinput input:focus-visible {
  background: #fff;
  background: var(--input-white);
  border-color: #6c5dd3;
  border-color: var(--primary-color);
}
.login-field-wrap {
  position: relative;
}

.login-title h4 {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  margin-bottom: 35px;
}

label.formlable {
  color: var(--text-color);
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1.6;
  letter-spacing: 0.9px;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  font-weight: 700;
  position: absolute;
  top: 14px;
  left: 25px;
}

.button-login button {
  height: 48px;
  padding: 0 24px;
  border-radius: 12px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  background-color: var(--primary-color);
  color: var(--input-white);
  border: 0;
  width: 100%;
  line-height: 24px;
  margin-top: 15px;
}

.button-login button:hover {
  background-color: var(--primary-color);
}

/************************************************************************************************************/
.menu {
  width: 100%;
  height: 50px;
  align-items: center;
}
#menu ~ .navbarclass .icon .menu .logo-wrap,#menu~.navbarclass .icon .menu .logo-text  {
  display: none;
}
#menu:checked~.navbarclass .icon .menu .logo-wrap
 {
  display: block;
  width: 40px;
  overflow: hidden;
}
#menu:checked~.navbarclass .icon .menu .logo-text{
  display: block;
}
#menu:checked ~ .navbarclass .icon .menu.justify-content-between {
  justify-content: space-between !important;
}

#menu ~ .navbarclass .icon .menu.justify-content-between {
  justify-content: center !important;
}

#menu:checked ~ .navbarclass .icon .menu h5 {
  display: block;
}

input#menu {
  display: none;
}
#menu:checked ~ .navbarclass .menu svg {
  transform: scale(1, 1);
  cursor: pointer;
}
.menu svg {
  transform: scale(-1, -1);
}
#menu .icon .menu svg {
  transform: rotate(-180deg);
  margin-left: 16px;
}

#menu + .icon .menu h5 {
  display: none;
}

#menu ~ .navbarclass {
  width: 76px;
  z-index: 11;
}

#menu:checked ~ .navbarclass {
  width: 256px;
}

#menu:checked .icon .menu svg {
  align-self: center;
  transition: width ease 0.12s;
  transform: rotate(0);
}
ul.sidemenu {
  padding: 0;
}

#menu:checked ~ .navbarclass .sidemenu li a {
  font-size: 14px;
  width: 100%;
  display: flex;
  font-weight: 500;
  padding: 0 40px;
}

.icon {
  width: 100%;
  padding: 4px 15px;
}

#menu:checked ~ nav .sidemenu li {
  padding: 0 20px;
}

#menu:checked ~ .navbarclass .sidemenu li svg {
  margin-right: 10px;
}

#menu:checked ~ .navbarclass .sidemenu li {
  padding: 0 0;
}

.sidemenu li svg {
  height: 24px;
  width: 24px;
  margin-right: 00px;
}

.sidemenu li {
  list-style-type: none;
  align-items: center;
  color: var(--text-color);
  overflow: hidden;
  display: flex;
  margin-bottom: 5px;
  cursor: pointer;
}

.sidemenu li.active a,
.sidemenu li.active a:hover {
  background-color: #e5e1ff;
}
/* 
 .sidemenu li.active a {
   color: var(--input-white)
 } */

.sidemenu li a:hover {
  background-color: #f5f5f5;
}
.image-preview-container {
  gap: 15px;
  flex-wrap: wrap;
}

.sidemenu li a {
  color: #53545e;
  text-decoration: none;
  font-size: 0;
  font-weight: 600;
  height: 45px;
  align-items: center;
  padding: 0 27px;
  display: flex;
  align-items: center;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
label.icon h5 {
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: var(--primary-color);
}

.navbarclass {
  overflow: hidden;
  width: 250px;
  position: fixed;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
  z-index: 7;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #e4e4e4;
  background: var(--input-white);
  height: 100vh;
}

.main-section,
.navbar {
  padding: 20px;
  width: calc(100% - 75px);
  float: right;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.navbar {
  border-bottom: 1px solid #e4e4e4;
  padding: 8px 20px;
  position: fixed;
  background: #fff;
  right: 0;
  z-index: 999;
}

.navbar .profile-image-wrap {
  width: 40px;
  height: 40px;
}

.profile-image-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-section {
  margin-top: 58px;
}

#menu:checked ~ .main-section,
#menu:checked ~ .navbar {
  width: calc(100% - 256px);
}

.data_table th {
  font-size: 16px;
  line-height: 1.38462;
  font-weight: 500;
  color: var(--input-color);
  padding: 10px 0px 10px 0px;
}

.data_table td {
  padding: 10px 0px 10px 0px;
  font-size: 14px;
  color: #11142d;  
  align-content: center;
}

.data_table .action-btn {
  width: max-content;
  margin: auto;
  position: relative;
  cursor: pointer;
}

.data_table .imageWrap {
  width: 60px;
  height: 60px;
  overflow: hidden;
}

.data_table .imageWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.data_table .user-full-name {
  cursor: pointer;
}

.data_table .user-full-name:hover {
  color: #795cd4;
}

.action-btn .action-menu {
  position: absolute;
  right: 25px;
  top: 0;
  margin: 0;
  list-style: none;
  padding: 0;
  border: 1px solid #dadada;
  border-radius: 8px;
  overflow: hidden;
}

.action-menu .action-item-menu {
  padding: 8px 10px;
  background: #fff;
  display: flex;
}

.action-item-menu:hover {
  background-color: #e5e1ff;
}

.checkbox__tick {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid #e4e4e4;
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

.checkbox__tick:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 10px;
  height: 9px;
  opacity: 0;
  background: url("../public/Assets/images/download.svg") no-repeat 50% 50% /
    100% auto;
  -webkit-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.checkbox__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 8;
  height: 20px;
  width: 20px;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick:before {
  opacity: 1;
}

.checkbox {
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.checkbox__in {
  display: flex;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.checkbox:hover .checkbox__tick {
  border-color: var(--primary-color);
}

table.data_table th {
  text-transform: capitalize;
}

/* table.data_table td {
  padding: 10px 0px 10px 15px;
  font-size: 14px;
  color: #11142d;
  line-height: 100%;
  text-transform: capitalize;
  align-content: center;
} */

.showWrap {
  height: 40px;
  width: 40px;
  background: linear-gradient(66deg, #6c5dd3 15.4%, #a372f2 84.6%);
  border-radius: 10px;
  position: relative;
  color: var(--input-white);
  border: 0;
  box-shadow: 0px 1px 6px 0px #7d5cd570;
}
.showWrap.rmvpopbtn {
  height: 44px;
  width: 44px;
}
.showWrap:hover {
  background: linear-gradient(66deg, #292b2f 15.4%, #50555c 84.6%);
}
.showWrap.rmvpopbtn svg {
  height: 16px;
  width: 16px;
}
.showWrap svg {
  height: 12px;
  width: 12px;
  align-self: center;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
}

.showWrap.hide {
  background: #ced3f7;
}

.showWrap img {
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  bottom: 0;
  right: 0;
  height: 30px;
  width: 30px;
}

.marginright {
  margin-right: 10px;
}

.mybtn {
  color: var(--input-white);
  padding: 10px 15px;
  border: 0;
  border-radius: 10px;
  text-transform: capitalize;
  box-shadow: 0px 1px 8px 0px #7d5cd53d;
  background: linear-gradient(66deg, #6c5dd3 15.4%, #af56d9 84.6%);
  text-decoration: none;
}

.mybtn:hover {
  background: linear-gradient(66deg, #292b2f 15.4%, #50555c 84.6%);
}

/********* Pagoination ***************/
.pagination__item.active {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--input-white);
}

.modal.show .modal-dialog {
  transform: none;
  transform: translateY(-50%);
  top: 50%;
}

/*********** popup input *****************/
.popup__input.search__input {
  border-color: #b2b3bd;
  margin: 0;
}

.search {
  position: relative;
}
 .pagination__next.leftarw{
  transform: rotate(180deg);
 }
.popup__input,
select.my-select {
  width: 100%;
  height: 45px;
  padding: 10px 20px;
  border-radius: 8px;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  outline: none;
  text-transform: capitalize;
  margin-bottom: 10px;
  border: 1px solid #dedede;
  outline: none;
  position: relative;
  z-index: 5;
}
.popup__input.is_invalid,
.my-select.errorinput,
.my-select.errorinput .css-13cymwt-control{
  border-color: red;
}
textarea.popup__input {
  min-height: 200px;
}
.switch.abswitch {
   position: absolute;
   right: 0;
   top: 0;
 }
.search svg {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.popup__input:focus,
select.my-select:focus {
  border-color: var(--primary-color);
}

select.my-select option {
  margin-top: 10px;
  padding: 20px;
}

.popup__label {
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.33333;
  font-weight: 500;
  color: var(--text-color);
  display: block;
}

/* #e4e4e4 */
/***************** Switch css ***************/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  top: 0;
  background-color: white;
  /* transition: .4s; */
  bottom: 0;
  margin: auto;
}

input:checked + .slider {
  background: linear-gradient(66deg, #6c5dd3 15.4%, #af56d9 84.6%);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

/*********** Pagination /****************/

.pagination__item,
.pagination__next {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 40px;
  margin: 8px 0 0 8px;
  border-radius: 12px;
  border: 1px solid #e4e4e4;
  font-size: 14px;
  font-weight: 700;
  color: #11142d;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  width: 40px;
}

/* .pagination__next {
   width: auto;
   padding: 0 25px;
 } */

.pagination__item:hover,
.pagination__next:hover {
  border-color: var(--primary-color);
}

button.pagination__next:disabled,
button.pagination__next[disabled] {
  border-color: #e4e4e4;
  color: #e4e4e4;
}

button.pagination__next:disabled svg {
  color: #e4e4e4 !important;
}

/************* dropdown arrow *****************/
.shownumber select {
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  z-index: 15;
  background-color: transparent;
}

.shownumber {
  margin-left: 10px;
  margin-top: 0px;
}

.imageWrap video {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.imageWrap.user {
  display: flex;
  gap: 10px;
}

.imageWrap .ant-image {
  width: 200px;
  height: 200px;
  position: unset;
  display: inline-block;
  border-radius: 9px;
  overflow: hidden;
  border: 2px solid #000;
}

.imageWrap .ant-image-img {
  width: 100%;
  height: 100%;
  vertical-align: unset;
  object-fit: cover;
}

.imageWrap .ant-image-mask {
  display: none;
}

.flag .imageWrap {
  width: 50px;
  height: 50px;
  overflow: hidden;
}

.flag .imageWrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-dialog {
  max-width: 1000px;
  max-height: 800px;
}

.profileform {
  display: flex;
  flex-wrap: wrap;
}

.btnrmvwrap {
  position: relative;
}

.profileform > div {
  flex: 32%;
  margin-right: 2%;
}

.profileform > div.full {
  flex: 100%;
  margin: 0;
}

.profileform > div:nth-child(3),
.profileform > div:nth-child(6),
.profileform>div:nth-child(9),
.profileform>div:nth-child(14),
.profileform>div:nth-child(17) {
  margin-right: 0;
}

.profileform > div:nth-child(11),
.profileform > div:nth-child(10) {
  flex: 49%;
  margin-bottom: 15px;
} 

.profileform > div:nth-child(11) {
  margin-right: 0;
}

.profileform .popup__label {
  margin-bottom: 5px;
}

.css-tj5bde-Svg {
  color: var(--input-color);
  height: 17px;
  width: 17px;
}

.remove-btn {
  height: 24px;
  width: 24px;
  z-index: 0;
  position: absolute;
  right: -7px;
  top: 0;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  margin-left: 0;
  background: var(--primary-color);
  color: white;
  line-height: 24px;
}

.remove-btn:hover {
  background-color: var(--input-white);
  color: var(--primary-color);
}

.remove-btn svg {
  height: 20px;
  width: 20px;
}

.remove-btn svg {
  height: 13px;
  width: 13px;
  position: absolute;
  left: 0;
  top: 0;
  margin: auto;
  right: 0;
  bottom: 0;
}

.logout-btn {
  position: absolute;
  top: 93%;
  left: 12px;
}

.svgwrap {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 8px;
  transform: translateY(50%);
  line-height: 100%;
  color: red;
}

.svgwrap.green {
  color: green;
}

button.forget-btn {
  background-color: transparent;
  border: 0;
  font-weight: 500;
  font-size: 14px;
}

.switch.form .slider:before {
  top: 1.8px;
}

.eye {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 12px;
  right: 12px;
}

.eye svg {
  color: var(--text-color);
}

.changebtn {
  background: transparent;
  color: var(--input-color);
  border: 0;
  padding: 0;
}

.container-login {
  max-width: 1650px;
  margin: 0 auto;
}

.logincontainerwrap {
  background: #ebebeb;
  border-radius: 16px;
}

.navbar .dropdown-toggle::after {
  display: none;
}

.navbar .show button {
  border-radius: 50%;
  padding: 0;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #53545e;
}

.navbar .btn {
  padding: 0;
  border-radius: 50%;
}

.navbar .show button:hover {
  color: var(--primary-color);
  background-color: transparent;
}

.navbar .show button:hover svg path {
  stroke: var(--primary-color);
}

.navbar .show .dropdown-menu.show {
  margin-top: 10px;
  border-color: #e4e4e4;
  background-color: #fff;
}

.navbar .show .btn-check:checked + .btn,
.navbar .show .btn.active,
.navbar .show .btn.show,
.navbar .show .btn:first-child:active,
.navbar .show :not(.btn-check) + .btn:active {
  border-color: transparent;
}

.navbar .btn-check:focus + .btn,
.navbar .btn:focus {
  box-shadow: none;
}

.svgwarp.drop {
  margin-right: 10px;
}

.navbar a.dropdown-item:hover {
  background: none;
}

.menu-title p {
  padding-left: 40px;
  font-size: 0px;
  font-weight: 400;
  color: var(--text-color);
  font-family: "Inter", sans-serif;
}

#menu:checked ~ .navbarclass .menu-title p {
  font-size: 12px;
}

.svgwarp svg path {
  stroke: #53545e;
}

.imageWrapcustum,
.imageWrap.profile {
  position: relative;
  height: 86px;
  width: 86px;
  border: 1px solid #6c5dd3;
  text-align: center;
  color: #6c5dd3;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: none;
  overflow: hidden;
}

.imageWrap.profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imageWrapcustum input[type="file"] {
  -webkit-appearance: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 86px;
  width: 86px;
  opacity: 0;
}

.imageWrapcustum:before {
  content: "+";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-size: 30px;
  font-weight: 300;
  line-height: 86px;
  margin: auto;
}

.my-select .css-1p3m7a8-multiValue {
  background-color: #f3f3f3;
  border-radius: 6px;
  padding: 4px 6px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.my-select .css-t3ipsp-control {
  border: 1px solid var(--primary-color);
  box-shadow: none;
  border-radius: 8px;
}

.my-select .css-d7l1ni-option {
  background-color: #ebe9fe;
}

.my-select .css-qbdosj-Input {
  margin: 5px;
}

.my-select .css-t3ipsp-control:hover {
  border-color: var(--primary-color);
}

.my-select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.custon:after {
  content: url("./assets/images/Image.svg");
  position: absolute;
  height: 0;
  background: #fff;
  width: 0;
  right: 25px;
  line-height: 49px;
  top: 0;
  z-index: 1;
}

.mybtn.black {
  background-color: var(--input-color);
}

.mybtn.black:hover {
  background-color: var(--primary-color);
}

.logo-content h4 {
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
}

.logo-wrap1 {
  height: 52px;
  width: 52px;
  position: relative;
}

.logo-wrap1 img {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.statussubadmin {
  height: 15px;
  width: 15px;
  background: red;
  border-radius: 50%;
  cursor: pointer;
}

.statussubadmin.green {
  background-color: green;
}

.showWrap.auto {
  width: 100%;
  padding: 0 15px;
  background: white;
  color: #000;
  border-radius: 0;
  box-shadow: none;
}

.showWrap.auto svg {
  display: inline-block;
  position: relative;
  left: auto;
  top: -2px;
  transform: none;
  margin-right: 10px;
  height: 15px;
  width: 15px;
}

.my-dropdown .dropdown-menu {
  width: auto;
}

.dotdisplay.showw {
  opacity: 1;
}

.dotdisplay {
  opacity: 0;
}

.btn-primary.my-dropdown {
  background-color: var(--input-white);
  color: var(--input-color);
  border: 0;
  box-shadow: none;
}

.btn-primary.my-dropdown svg {
  height: 20px;
}

.btn-primary.my-dropdown:active,
.show > .btn-primary.my-dropdown.dropdown-toggle:focus {
  background-color: transparent;
  color: var(--input-color);
  box-shadow: none;
}

.my-dropdown.dropdown-toggle::after {
  display: none;
}

.fulllengthclass {
  width: 100%;
}

.mycheckbox.borderg {
  border: 1px solid green;
}

.categoryWrap {
  border-bottom: 1px solid #808191;
  margin: 10px 0;
  padding-bottom: 10px;
}

.categoryWrap .d-flex div {
  margin-right: 10px;
  align-items: center;
}

button.subbtn {
  color: var(--primary-color);
  line-height: 100%;
  height: 30px;
  border: 0;
  background: #fff;
  margin-left: 15px;
}

button.subbtn svg path {
  stroke: var(--primary-color);
}

.loader-1 {
  width: 25px;
  height: 25px;
  border: 3px solid #fff;
  border-bottom-color: #af56d9;
  border-radius: 50%;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  display: none;
}

.imageWrap.video {
  width: 100px;
  height: 100px;
}

.loader-1.show {
  display: inline-block;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.alert-success.fixed-top.rr {
  left: auto;
  top: 15px;
  right: 10px;
}

.error p {
  color: red;
  margin: 0 0 5px;
  font-size: 14px;
}

.my-select .css-1nmdiq5-menu{
  z-index: 15;
}

.user-info{
  background: #f5f5f5;
  padding: 15px;
  border-radius: 20px;
}

/* Plans Component */

.plans-box {
  width: 100%;
  border-radius: 6px;
  position: relative;
  padding: 0px 10px 10px;
}

.plans-duration {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 0px;
  text-align: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.plans-tags {
  width: max-content;
  padding: 6px 18px;
  border-radius: 6px;
  position: absolute;
  left: 50%;
  bottom: -6%;
  font-size: 14px;
  transform: translateX(-50%);
  font-weight: 500;
}

.plans-box-description {
  text-align: center;
  background-color: #000000;
}

.plans-price {
  font-size: 24px;
  color: #FFFFFF;
  margin: 0;
  font-weight: 500;
}

.plans-price {
  font-size: 24px;
  color: #FFFFFF;
  margin: 0;
  font-weight: 500;
  padding: 22px 0px 6px 0px;
}

.plans-dived-price {
  margin: 0;
  color: #FFFFFF;
  font-size: 19px;
  font-weight: 300;
  padding-bottom: 30px;
}

.plans-action-btn {
  position: absolute;
  cursor: pointer;
}

.plans-action-btn.plans-edit {
  top: 9px;
  right: 9px;
}

.plans-action-btn.plans-delete {
  top: 9px;
  right: 42px;
}

.package-plans {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
}

.form-error {
  font-size: 14px;
  color: crimson;
}

.feat-btn {
  padding: 0 12px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: burlywood;
  border-radius: 10px;
}

.topupplans-box {
  position: relative;
  border-radius: 6px;
  padding: 10px;
}

.topupplans-discount {
  padding: 5px 10px;
  border-radius: 18px;
  /* color: #FFFFFF; */
  font-size: 13px;
  font-weight: 600;
}

.topupplans-icon {
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin: auto;
  margin-top: 26px;
}

.topupplans-icon svg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: 99;
}

.topupplans-superlike::before {
  content: "";
  position: absolute;
  background-color: #fb2a75;
  width: 50px;
  height: 50px;
  filter: blur(22px);
  left: 50%;
  transform: translateX(-50%);
}

.topupplans-boost::before {
  content: "";
  position: absolute;
  background-color: #F9EF63;
  width: 50px;
  height: 50px;
  filter: blur(22px);
  left: 50%;
  transform: translateX(-50%);
}

.topupplans-diamond::before {
  content: "";
  position: absolute;
  background-color: #45D6F6;
  width: 50px;
  height: 50px;
  filter: blur(22px);
  left: 50%;
  transform: translateX(-50%);
}

.topupplans-box-description {
  text-align: center;
  margin-top: 15px;
}

.topupplans-box-description {
  text-align: center;
  margin-top: 15px;
}

.topupplans-qty {
  margin-bottom: 8px;
  font-size: 22px;
  color: #FFFFFF;
}

.topupplans-price {
  font-size: 20px;
  color: #FFFFFF;
  margin: 0;
}

.topupplans-edit {
  position: absolute;
  right: 9px;
  top: 50px;
  cursor: pointer;
  color: #FFFFFF;
}

.topupplans-delete {
  position: absolute;
  right: 14px;
  top: 80px;
  cursor: pointer;
  color: #FFFFFF;
}

.pkg-feat-modal .modal-content {
  overflow: hidden;
  border: none;
}

.pkg-feat-modal .modal-body {
  background-color: #0A0406;
  overflow: hidden;
}

.pkg-feat-modal .modal-dialog {
  max-width: 500px;
}

.feat-title {
  font-size: 20px;
  text-align: center;
  font-weight: 600;
  color: #dddddd;
  margin-bottom: 14px;
}

.feat-boxs {
  border: 1px solid #383838;
  border-radius: 8px;
  overflow: hidden;
}

.feat-boxs:nth-of-type(3) {
  margin: 20px 0px;
}

.feat-boxs-title {
  background-color: #151515;
  text-align: center;
  padding: 8px 0px;
  color: #d7d7d7;
  font-size: 13px;
  font-weight: 300;
}

.features {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  margin: 8px 0px;
}

.feat-img-title {
  color: #FFF;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  gap: 12px;
}

.features-img {
  width: 25px;
  height: 25px;
  overflow: hidden;
}

.second-title {
  color: #878787;
  font-weight: 400;
}

.features-img img {
  width: 100%;
  height: 100%;
  object-fit: cove;
}